@use '../abstracts' as *;

.dashboard__topbar {
    padding: 0.9rem .5rem;
    background-color: rgba(247, 248, 250, 1);
    position: sticky;
    top: 0;
    z-index: 9;

    @include respond(tablet-lg) {
        padding: 0.85rem 3rem 1.5rem;
    }

    &--heading {
        font-size: clamp(1.5rem, 2vw, 2.125rem);
        font-weight: 600;
        margin: 0;
        line-height: 1;
        color: var(--bg--dark);
    }
}

.button__box {
    @include respond(tablet-md) {
        text-align: end;
        margin-top: 0rem;
    }

    &.font--14 {
        .button--outlined {
            font-size: .8rem;
            padding: 1.15em 1.15em;

            @include respond(tablet-xl) {
                font-size: .875rem;
                padding: 1.15em 2.3em;
            }
        }
    }
}

.button--outlined {
    font-size: .875rem;
    padding: 1.15em 2.5em;
    line-height: 1;

    &.active {
        background-image: linear-gradient(74.28deg, #F75238 32.74%, #FFBA33 132.21%);
        color: var(--brand--font--white);
        font-weight: 600;
    }

    border-radius: 50px;
    color: var(--brand--font--link);
    background-color: var(--bg--primary);

    @include respond(tablet-lg) {
        +.button--outlined {
            margin-left: 1rem;
        }
    }
}

.card__columns {
    display: grid;
    grid-gap: 1rem;
    margin-top: 1.875rem;

    @include respond(mobile) {
        grid-gap: 1.5rem;
        grid-template-columns: repeat(2, 1fr);
    }

    @include respond(desktop-lg) {
        grid-gap: 1.5rem;
        grid-template-columns: repeat(4, 1fr);
    }

    &--box {
        border-radius: 1rem;
        position: relative;
        overflow: hidden;
        padding: 1.35rem 1.25rem;
    }

    &--inner {
        display: grid;
        align-items: center;
        position: relative;
        grid-gap: 1rem;
        min-height: 6.5rem;
        grid-template-columns: 120px 1fr;

        @include respond(tablet-lg) {
            grid-template-columns: 1fr 6.5rem;
            align-items: flex-end;
            min-height: 7.5rem;
        }

        @include respond(tablet-lg) {
            grid-template-columns: auto;
        }

        @include respond(desktop-xl) {
            grid-template-columns: 1fr 4.5rem;
            min-height: auto;
        }

        @include respond(desktop-xxl) {
            grid-template-columns: 1fr 6.5rem;
            min-height: 7.5rem;
        }

        &--text {
            padding-bottom: .5rem;
        }

        &--icon {
            background-color: rgba(30, 28, 75, 0.1);
            padding: 1.5rem;
            border-radius: .5rem;
            width: 6.5rem;
            position: relative;

            svg {
                margin: auto;
            }

            @include respond(tablet-lg) {
                padding: 2.5rem;
                height: 5rem;

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 2rem;
                }
            }

            @include respond(desktop-lg) {
                height: 4rem;
                width: 4.5rem;
                padding: 1.5rem;

                svg {
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }

            @include respond(desktop-xl) {
                order: 1;
            }

            @include respond(desktop-xxl) {
                width: 6.5rem;
                height: 7.3rem;

                svg {
                    width: auto;
                    height: auto;
                }
            }
        }
    }

    &--title {
        color: var(--brand--font--white);
        font-weight: 500;
        font-size: .875rem;
        line-height: 1;
        display: block;

        @include respond(desktop-xxl) {
            font-size: 1rem;
        }
    }

    &--numbers {
        color: var(--brand--font--white);
        font-weight: 600;
        font-size: 2rem;
        line-height: 1;
        display: block;
        margin-top: .75rem;
        word-break: break-all;

        @include respond(desktop-xxl) {
            font-size: 2.25rem;
        }
    }

    &--subtext {
        margin-top: 0.5rem;
        font-weight: 600;
        font-size: .875rem;
    }
}

.stripe {
    &--danger {
        background-image: linear-gradient(230.26deg, #FF275B 12.29%, #FF275B 72.7%);
    }

    &--primary {
        background-image: radial-gradient(100.85% 285.11% at 14.52% 92.09%, #8852FF 0%, #AA27FF 100%);

        .stripe--shape {
            svg {
                path {
                    fill: rgba(135, 46, 238, 0.23);
                }
            }
        }
    }

    &--secondary {
        background-image: radial-gradient(100.85% 285.11% at 14.52% 92.09%, #25254d 0%, #191972 100%);

        .stripe--shape {
            svg {
                path {
                    fill: rgba(63, 172, 186, 0.23);
                }
            }
        }

        .card__columns--inner--icon {
            background-color: rgb(162, 161, 182, 0.1);
        }
    }

    &--newPrimary {
        background-image: radial-gradient(100.85% 285.11% at 14.52% 92.09%, #208f99 0%, #35d6c2 100%);

        .stripe--shape {
            svg {
                path {
                    fill: rgba(228, 190, 16, 0.23);
                }
            }

            img {
                mix-blend-mode: luminosity;
            }
        }
    }

    &--creatorCard {
        background-image: radial-gradient(100.85% 285.11% at 14.52% 92.09%, #4972f6 0%, #2682f6 100%);

        .stripe--shape {
            svg {
                path {
                    fill: rgba(228, 190, 16, 0.23);
                }
            }

            img {
                mix-blend-mode: color-dodge;
            }
        }
    }


    &--episodeGiftCard {
        background-image: radial-gradient(100.85% 285.11% at 14.52% 92.09%, #d819d7 0%, rgb(146 26 174) 100%);

        .stripe--shape {
            svg {
                path {
                    fill: rgba(228, 190, 16, 0.23);
                }
            }
        }

        img {
            mix-blend-mode: luminosity;
        }
    }


    &--accent {
        .card__columns--inner--icon {
            background-color: rgba(37, 201, 250, 0.2);
        }

        background-image: linear-gradient(244.31deg, #2BFFCC -3.18%, #21CAFF 69.26%);

        .stripe--shape {
            svg {
                path {
                    fill: rgba(68, 158, 241, 0.1);
                }
            }
        }
    }

    &--warning {
        .card__columns--inner--icon {
            background-color: rgba(244, 100, 64, 0.8);
        }

        background-image: linear-gradient(69.32deg, #F6544B 14.01%, #F89427 95%);

        .stripe--shape {
            svg {
                path {
                    fill: rgba(233, 38, 89, 0.1);
                }
            }
        }
    }

    &--packages {
        background-image: linear-gradient(69.32deg, #dcae2e 14.01%, #f8cb2c 95%);
        mix-blend-mode: luminosity;

        .stripe--shape {
            svg {
                path {
                    fill: rgba(233, 38, 89, 0.1);
                }
            }
        }
    }

    &--users {
        .card__columns--inner--icon {
            background-color: rgba(255, 255, 255, 0.1);
        }

        background-image: linear-gradient(69.32deg, #145586 14.01%, #0d2d7c 95%);

        .stripe--shape {
            svg {
                path {
                    fill: rgba(233, 38, 89, 0.1);
                }
            }
        }
    }
}

.stripe--shape {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    svg,
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.dashboard__content {
    padding: 0.5rem 1rem;

    @include respond(tablet-lg) {
        // padding: 2.5rem 3rem;
    }
}

// body {
//     .datatable__custom, .MuiDataGrid-columnHeaders {
//         border: 0;
//     }
//     .pe--0 {
//         .MuiDataGrid-cell--textRight { 
//             justify-content: flex-start;
//         }
//         .MuiDataGrid-columnHeader--alignRight {
//             > .MuiDataGrid-columnHeaderDraggableContainer {
//                 > * {
//                     flex-direction: row;
//                 }
//             }
//         }
//     }
//     .searchbar--absolute {
//         .searchvalue {
//             @include respond(tablet-lg) {
//                 width: 350px;
//             }
//         }
//         @include respond(desktop-lg) {
//             > div {
//                 &:first-child {
//                     position: absolute;
//                     top: -80px;
//                     right: 0;
//                     max-width: 400px;
//                 }
//             }
//         }
//         &.searchbar__size {
//             .searchvalue {
//                 width: 100%;
//             }
//             @include respond(desktop-lg) {
//                 > div {
//                     &:first-child {
//                         max-width: 300px;
//                     }
//                 }
//             }
//         }
//     }
//     .searchvalue {
//         margin-left: 0;
//         margin-top: 0;
//         input {
//             font-weight: 500;
//             &::placeholder {
//                 color: rgba(194, 193, 231, 1);
//                 font-weight: 500;
//             }
//         }
//         @include respond(tablet-md) {
//             width: 400px;
//         }
//         > * {
//             background-color: var(--bg--primary);
//             border-radius: .9rem;
//             fieldset {
//                 border: 0;
//             }
//         }
//     }
//     .MuiDataGrid-row {
//         background-color: rgba(30, 28, 75, 1);
//         border-radius: 14px;
//         padding-right: 70px;
//         .MuiDataGrid-cell {
//             &:nth-child(1) {
//                 padding-left: 1rem !important;
//             }
//             border-bottom: 0;
//             position: relative;
//             &::after {
//                 content: '';
//                 position: absolute;
//                 top: 8px;
//                 bottom: 8px;
//                 right: 6px;
//                 width: 1px;
//                 background: rgba(110, 108, 163, 0.2);
//             }
//             &:last-child, &:nth-last-child(2)  {
//                 &::after {
//                     display: none;
//                 }
//             }
//             &:nth-child(3) {
//                 text-transform: capitalize;
//             }
//             &:focus {
//                 outline: none;
//             }
//         }
//         & + .MuiDataGrid-row {
//             margin-top: .65rem;
//         }
//         &.Mui-selected {
//             background-color: #312e70 !important;
//         }
//     }
// }
// .MuiDataGrid-columnHeader {
//     outline: none !important;
//     * {
//         color: var(--brand--font--link);
//     }
//     .MuiDataGrid-columnSeparator {
//         display: none !important;
//     }
// }
// .MuiDataGrid-columnHeaderTitle {
//     font-weight: 400 !important;
//     font-size: .875rem !important;
// }
// .MuiDataGrid-cellContent {
//     font-weight: 400 !important;
//     font-size: .875rem !important;
// }
// .MuiCheckbox-root {
//     .MuiSvgIcon-root {
//         font-size: 1.775rem !important;
//         border-radius: 8px;
//         background-color: var(--bg--primary);
//         path {
//             fill: var(--bg--primary);
//         }
//     }
// }
// .MuiDataGrid-row {
//     .MuiCheckbox-root {
//         .MuiSvgIcon-root {
//             background-color: #191840;
//             path {
//                 fill: #191840;
//             }
//         }
//         &.Mui-checked {
//             .MuiSvgIcon-root {
//                 color: var(--brand--font--white);
//                 background: #312e70;
//                 path {
//                     fill: var(--brand--font--white);
//                 }
//             }
//         }
//     }
// }

// .MuiDataGrid-cellCheckbox{
//     min-width: 100px !important;
//     max-width: 100px !important;
// }
// .MuiDataGrid-columnHeaderCheckbox{
//     min-width: 100px !important;
//     max-width: 100px !important;
// }

// .MuiDataGrid-columnHeader {
//     .MuiCheckbox-root {
//         &.Mui-checked {
//             .MuiSvgIcon-root {
//                 color: var(--brand--font--white);
//                 path {
//                     fill: var(--brand--font--white);
//                 }
//             }
//         }
//     }
// }
ul#stream-countries-listbox,
.checkbox--white {
    .MuiCheckbox-root {
        &.Mui-checked {
            .MuiSvgIcon-root {
                background-color: transparent;

                path {
                    fill: #fff;
                }
            }
        }
    }
}

.mb--20 {
    margin-bottom: 1rem;
}

.mb--md--0 {
    @include respond(tablet-md) {
        margin-bottom: 0rem;
    }
}