@use '../abstracts' as *;
.container {
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
    @include respond(mobile) {
        max-width: 34rem;
    }
    @include respond(tablet-md) {
        max-width: 45rem;
    }
    @include respond(tablet-lg) {
        max-width: 60rem;
    }
    @include respond(desktop-lg) {
        max-width: 72rem;
    }
    @include respond(desktop-xl) {
        max-width: 82rem;
    }
}
.grid__container {
    display: grid;
    grid-gap: 1.5rem;
}
.grid-md-2-column {
    @include respond(tablet-md) {
        grid-template-columns: repeat(2,1fr);
    }
}
.grid-md-1-2-column {
    @include respond(tablet-md) {
        grid-template-columns: 1.3fr 2fr;
    }
}