@use '../abstracts' as *;


.login__innerarea input:-webkit-autofill,
.llogin__innerarea input:-webkit-autofill:hover,
.login__innerarea input:-webkit-autofill:focus,
.login__innerarea input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px var(--bg--primary) inset !important;
    -webkit-text-fill-color: var(--color--text) !important;
    box-shadow: 0 0 0 1000px var(--bg--primary) inset !important;
    -webkit-box-shadow: 0 0 0 1000px var(--bg--primary) inset !important;
    -webkit-filter: drop-shadow(0 0 14px #0c5f5c0f);
    filter: drop-shadow(0 0 14px #0c5f5c0f);
    border-color: var(--brand--primary) !important;
    -webkit-text-fill-color: var(--brand--font--white) !important;
    font-size: inherit !important;
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
    -webkit-text-fill-color: var(--color--text) !important;
    border-color: #d7dae3 !important;
}

.signin__tabs {
    background: #02406c;
    max-width: 480px;
    width: 100%;
    border-radius: 30px;
    border: 0 !important;
    margin-left: auto;
    margin-right: auto;

    >* {
        overflow: visible !important;

        >* {
            overflow: visible !important;

            >span {
                &.MuiTabs-indicator {
                    display: none;
                }
            }
        }
    }

    div[aria-label="99.94 Login Options"] {
        >button {
            flex: 0 0 50%;
            border-radius: 30px;
            font-size: 15px;
            font-weight: 400;
            overflow: visible;

            &.Mui-selected {
                background-image: linear-gradient(132.14deg, #2374ad 20.93%, #609457 90.47%);
                font-weight: 600;
                color: var(--brand--font--white);
            }
        }
    }
}

.datatable__custom {
    input.MuiInputBase-input {
        padding-left: 10px !important;
    }
}

.showdetail__descBox {
    >div {
        > :first-child {
            padding-right: 10px;
        }
    }
}