@use '../abstracts' as *;
.showdetail__topbar {
    display: grid;
    grid-gap: 1rem;
    @include respond(tablet-md) {
        grid-template-columns: 225px 1fr;
    }
    &--title {
        font-size: 1rem;
        font-weight: 600;
        margin-top: .5rem;
        max-width: 650px;
        @include respond(tablet-md) {
            font-size: 1.25rem;
        }
    }
}
.showdetail__imgBox {
    width: 100%;
    max-width: 130px;
    height: 130px;
    overflow: hidden;
    border-radius: 1rem;
    padding: .4rem;
    background: #2F2D62;
    @include respond(tablet-md) {
        max-width: 220px;
        height: 220px;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        border-radius: 1rem;
    }
}
.showdetail__ratingBox {
    margin-top: .75rem;
    &__outer {
        display: inline-flex;
        align-items: center;
        border: 1px solid #6E6CA3;
        padding: .5em 1em;
        text-align: center;
        background-color: #232157;
        border-radius: 50px;
        color: var(--brand--font--white);
        b {
            font-weight: 700;
            display: inline-block;
            vertical-align: middle;
            font-size: .875rem;
        }
        svg {
            display: inline-block;
            margin-left: 2px;
            margin-right: 2px;
        }
        span {
            font-weight: 400;
            display: inline-block;
            vertical-align: middle;
            font-size: .875rem;
        }
    }
}
.showdetail__descBox {
    font-weight: 400;
    margin-top: 1.5rem;
    max-width: 750px;
    line-height: 1.75em;
    font-size: .9rem;
    color: #dcdbf3;
    text-transform: capitalize;
    a {
        color: #38adf7;
    }
}
.profie__circle__box {
    display: inline-block;
    margin-right: .5rem;
    width: 4rem;
    vertical-align: top;
    &--img {
        width: 54px;
        height: 54px;
        border-radius: 50%;
        overflow: hidden;
        border: 3px solid #6E6CA3;
        display: block;
        margin-left: auto;
        margin-right: auto;
        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }
    p {
        font-size: .75rem;
        font-weight: 500;
        word-break: break-all;
        text-align: center;
        margin-top: .5rem;
    }
}
.showdetail__creators {
    margin-top: .875rem;
    .creator--title {
        font-weight: 700;
        font-size: 1rem;
    }
    @include respond(tablet-md) {
        font-size: 1.125rem;
    }
}
.showdetail__area {
    margin-top: .75rem;
}
.max--680 {
    max-width: 680px;
}
.aspect__ratio--16by9 {
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;
    border-radius: 13px;
    overflow: hidden;
    border: 8px solid #35337a;
    > img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        object-fit: cover;
        object-position: top;
    }
}
.episode__topbar--title {
    color: var(--brand--font--white);
    font-size: 1.15rem;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 1.3em;
    margin-top: 1.5rem;
    @include respond(tablet-lg) {
        font-size: 1.25rem;
    }
}
.episode__metadetail {
    margin-top: .5rem;
    &--title {
        font-weight: 500;
        font-size: 1rem;
        color: rgba(255,255,255,0.75);
    }
    &--epno {
        font-weight: 500;
        font-size: 1rem;
        color: rgba(255,255,255,0.75);
    }
    &--created {
        font-weight: 500;
        font-size: 1rem;
        color: rgba(255,255,255,0.75);
        &::before {
            content: "\00B7";
            padding: 0 5px;
            font-weight: 700;
        }
    }
}
.episode__duration {
    display: inline-block;
    margin-top: 1rem;
    background: #262461;
    padding: 0.52em 1em;
    font-weight: 500;
    font-size: .875rem;
    border-radius: 0.35em;
}
.max--950 {
    max-width: 950px;
}
.media__playerbutton {
    margin-top: 2rem;
}
.button__text__navigate {
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
}
.breadcrumb__title {
    word-break: break-word;
    // font-size: 1.15rem;
    // letter-spacing: 0;
    // font-weight: 500;
    padding-left: 10px;
}
.view-actions {
    position: absolute !important;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    max-width: none !important;
    z-index: 1;
    a {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 2rem;
        // span {
        //     transform: rotate(180deg);
        // }
    }
    &::after {
        display: none;
    }
}
.MuiDataGrid-row {
    position: relative;
}
.delete-actions, .edit-actions {
    position: relative;
    z-index: 2;
}
.max--650 {
    max-width: 650px;
}
.app__borderbox {
    border: 2px solid #323152;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    background: rgb(31 30 59 / 13%);
    box-shadow: 0 8px 32px 0 rgba(42,59,89,.13)!important;
    backdrop-filter: blur(15px)!important;
    -webkit-backdrop-filter: blur(15px)!important;
    overflow: hidden;
    .p--4 {
        padding: 2rem 1.5rem 0.5rem;
        border-bottom: 1px solid #2e2d4d;
    }
}
// .checkboxbar {
//     > label {
//         min-width: 60px;
//         border-radius: 8px;
//         min-height: 60px;
//         margin-right: 1rem;
//         position: relative;
//         justify-content: center;
//         align-items: center;
//         overflow: hidden;
//         margin-left: 0;
//         .MuiCheckbox-root {
//             position: absolute;
//             top: 0;
//             left: 0;
//             right: 0;
//             bottom: 0;
//             padding: 0;
//             background: var(--bg--primary);
//             border-radius: 0;
//             &.Mui-checked {
//                 background-image: linear-gradient(132.14deg, #F89E24 6.93%, #F6413C 90.47%) !important;
//                 svg {
//                     background: transparent;
//                     * {
//                         fill : transparent;
//                     }
//                 }
//             }
//         }
//         .MuiTypography-root {
//             font-size: 24px;
//             padding: 0;
//             position: relative;
//         }
//     }
// }
.checkboxbar {
    > label {
        .MuiTypography-root {
            font-weight: 400;
        }
    }
}
.ratio2-1 {
    position: relative;  
    &:before {
      content: '';
      display: block;
      padding-top: 50%; // ratio 2:1
    }
  
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
}
.ratio16-9 {
    position: relative;  
    &:before {
      content: '';
      display: block;
      padding-top: 56.25%; // ratio 16:9
    }
  
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
}
.fieldset__custom__react {
    border-width: 2px;
    padding: 1rem;
    border-radius: 6px;
    border-color: #33324f;
    border-style: solid;
    position: relative;
    .custom--legend {
        font-size: .875rem;
        font-weight: 400;
        padding-left: 0.35rem;
        padding-right: 0.35rem;
        top: -10px;
        background: #191840;
        position: absolute;
    }
    .checkbox--white {
        margin-right: 1.5rem;
    }
}
.disabled--event {
    pointer-events: none;
    cursor: not-allowed;
    .input-inner-transparent {
        .MuiOutlinedInput-root {
            background-color: #333253;
        }
    }
}
// audio::-webkit-media-controls-enclosure {
//     border-radius: 8px;
//     background-color:  #F89E24;
// }