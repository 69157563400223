@use '../abstracts' as *;

.minh--100vh {
    min-height: 100vh;
}

.maxh--100 {
    max-height: 100%;
}

.mx--auto {
    margin-left: auto;
    margin-right: auto;
}

.maxw--100 {
    max-width: 100%;
}

.login--heading {
    font-size: 1.5rem;
    color: var(--brand--font--white);
    margin: 0;
    line-height: normal;
    font-weight: 500;

    @include respond(tablet-md) {
        font-size: 1.875rem;
    }
}

.login--text {
    font-size: .875rem;
    color: var(--brand--font--white);
    font-weight: 400;
    margin-bottom: 0;
    margin-top: .85rem;

    @include respond(tablet-md) {
        font-size: 1rem;
    }
}

.login--wrapperbox {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
}

.buttonlogin {
    margin-top: 2.5rem;

    button,
    a {
        width: 100%;
    }
}

.login__textfiled {
    width: 100%;
    max-width: 600px;
}

.login__innerarea {
    padding: 0px 1rem;
}

.brandlogo {

    svg,
    img {
        max-width: 100px;
        margin: 0 auto;
        height: 110px;

        @include respond(tablet-lg) {
            height: auto;
        }
    }
}

.overflow--hidden {
    overflow: hidden;
}

.h-inherit {
    height: inherit;
}

.text--center {
    text-align: center;
}

.text--lg--start {
    @include respond(tablet-lg) {
        text-align: start;
    }
}

.ms--1 {
    margin-left: .5rem;
}

.max--760 {
    max-width: 760px;
}

.tag-t-0 {
    >* {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}

.text--end {
    text-align: end;
}

.dateoutlined {
    label {
        color: var(--brand--font--white) !important;

        &.Mui-focused {
            color: var(--brand--font--white);

            fieldset {
                border-width: 1px;
            }
        }

        &.Mui-error {
            color: #fff !important;
        }
    }

    .MuiOutlinedInput-root {
        &.Mui-error {
            fieldset {
                border-color: rgba(255, 255, 255, 0.23);
            }
        }

        &:hover {
            fieldset {
                border-color: var(--brand--primary) !important;
                border-width: 1px;
            }
        }
    }
}

.align--items--center {
    align-items: center;
}

body {
    -webkit-tap-highlight-color: transparent;
}

.avatorpic--16by9 {
    >img {
        aspect-ratio: 16/9;
        width: auto;
        object-position: left;
        height: auto;
        object-fit: contain;
    }
}

.text--initial {
    text-transform: initial !important;
}

.text--center {
    text-align: center;
}

.showdetail__descBox>div>div:nth-child(3)>div {
    background-color: #ffff !important;
}

.cs__range {
    z-index: 1;

    @include respond(tablet-md) {
        // position: absolute;
        // right: 0;
        // top: -10px;
        max-width: 500px;
    }
}

.ps-md-4 {
    @include respond(tablet-md) {
        padding-left: 20px;
    }
}

.MuiDataGrid-selectedRowCount {
    visibility: hidden !important;
}


.cursor__pointer .MuiDataGrid-row {
    cursor: pointer;
}

.shw-dtl-dt-rng {
    font-size: 14px;
    line-height: normal;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 1.5rem;
    display: inline-block;
    background: rgb(255, 255, 255, 0.03);
    padding: 0.6em 1em;
    border-radius: 4px;
    // text-align: right;
}

.w-100 {
    width: 100%;

    &.w-md-auto {
        @include respond(tablet-md) {
            width: auto;
        }
    }
}

.max-w-180 {
    max-width: 200px !important;
}

.text--dark {
    color: var(--bg--dark);
}

.creator__side {
    width: 80%;
}

.position--relative {
    position: relative;
}

.table__actionbuttons {
    // margin: 20px 0px 10px;
    // padding: 0px 15px;
    text-align: end;

    @media only screen and (min-width: 900px) {
        margin: 0;
        position: absolute;
        top: 40px;
        right: 40px;
        z-index: 1;
        padding: 0;
    }

    @include respond(tablet-lg) {
        // top: 70px;
        right: 70px;
    }
}

.MuiLinearProgress-root {
    >span {
        background: var(--brand--success);
    }
}

.red--whiteIcon {
    svg {
        color: #fff;
    }
}

.dashboard__content__cards {
    display: grid;
    grid-gap: 1.25rem;
    padding: 0 15px;

    @include respond(tablet-md) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include respond(desktop-lg) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.new__color__cards {
    margin-bottom: 1.875rem;
    background-color: #fff;
    transition: all .5s ease-in-out;
    position: relative;
    border: 0px solid transparent;
    border-radius: 1.75rem;
    box-shadow: 0px 5px 5px 0px rgb(82 63 105 / 5%);
    padding: 35px 30px 45px 30px;

    &.warning {
        background-color: #ffa755;
    }

    &.success {
        background-color: var(--brand--success);
    }

    &.purple {
        background-color: rgb(180, 141, 211);
    }

    &.primary {
        background-color: var(--brand--font--link);
    }

    &::after {
        position: absolute;
        content: "";
        display: inline-block;
        height: 120px;
        width: 120px;
        transform: rotate(40deg);
        top: -35px;
        right: -35px;
        border-radius: 1.75rem;
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(to right, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#26ffffff', endColorstr='#00ffffff', GradientType=1);
    }

    &--grid {
        display: grid;
        grid-template-columns: 60px 1fr;
        grid-gap: 1rem;

        .icon {
            height: 60px;
            width: 60px;
            min-width: 60px;
            line-height: 54px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.4);
            text-align: center;

            svg {
                path {
                    fill: var(--brand--font--white);
                }
            }
        }

        p {
            font-size: 38px;
            margin-bottom: 0px;
            color: #fff;
            font-weight: 600;
        }

        span {
            font-size: 18px;
            line-height: 1.5;
            display: block;
            color: #fff;
            font-weight: 400;
        }
    }
}


.MuiDataGrid-iconSeparator {
    visibility: hidden;
}

.active--class {
    background: linear-gradient(132.14deg, #045C9C 20.93%, #4B923F 90.47%);
    color: white;
    border: none;
    cursor: pointer;
    font-weight: 600;
    padding: 0.7em 2em !important;
}

.cancel-class {
    color: black;
    cursor: pointer;
    border-width: 1px;
}

.cancel-class:hover {
    background-color: black;
    color: white;

}

.deleted-class {
    cursor: pointer;
    color: var(--brand--danger);
    background: white;
    border-color: #ff275b;
    border-width: 1px;
}

.deleted-class:hover {
    background-color: var(--brand--danger);
    color: #fff;
}

.css-i4bv87-MuiSvgIcon-root {
    padding: 0px;
    border: none;
}

.button.Mui-selected {
    background-image: linear-gradient(132.14deg, #045C9C 20.93%, #4B923F 90.47%);

}

.signin__tabs {
    background-color: #045C9C;

}

.question_btn {
    text-decoration: none;
    border: 1px solid;
    margin: 5px;
    color: #02406c;
    border-radius: 7px;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 12px;
    cursor: pointer;
    background: white;
}

.question_btn:hover {
    color: #fff;
    background: #02406c;
}

.edit_btn {
    color: #02406c;
    padding: 6px 8px;
    border: 0px solid;
    background-color: transparent;
    border-radius: 8px;
    cursor: pointer;
    height: fit-content;
    margin-left: 5px;
}

.edit_btn:hover {
    color: #fff;
    background: #02406c;
}

.btn_group {
    align-items: center;
}

.lock_btn {
    color: #ff1100ed;
    padding: 6px 8px;
    border: 0px solid;
    background-color: transparent;
    border-radius: 8px;
    cursor: pointer;
    height: fit-content;
    // margin: 5px;
    border-color: #d502004f;
    margin: 0 0 0 5px;
}

.lock_btn:hover {
    color: #fff;
    background: #ff1100ed;

}

.lock_btn:active {
    color: #29b82f;
    border-color: #29b82f;
}

.unlock_btn {
    color: #29b82f;
    padding: 6px 8px;
    border: 0px solid;
    background-color: transparent;
    border-radius: 8px;
    cursor: pointer;
    height: fit-content;
    // margin-top: 5px;
    border-color: #29b82f;
    margin: 0 0 0 5px;
}

.unlock_btn:hover {
    color: #fff;
    background: #4caf50;
}

.dashboard__content .card__layout {
    background-color: #fff;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 4px 10px 8px rgb(2 64 108 / 50%);
    margin-left: 30px;
    overflow: hidden;
    width: 30%;
    margin-top: 2%;
    border-radius: 0px;
    padding: 0px 0px 0px 0px;
    border: 1px;
}

.dashboard__content .category_title {
    background-color: #02406d;
    width: 100%;
    text-align: center;
    height: 50px;
    color: white;
    padding: 10px;
    border-radius: 8px 8px 0px 0px;
}

.dashboard__content .subcategory_title {
    text-align: center;
    margin-top: 40px;
    cursor: pointer;
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 30px;
}

.subcategoryScroll {
    display: flex;
    flex-wrap: wrap;
    margin-left: 8px;
}

.dashboard__content .subcategorydetail_part {
    border: 1px solid #dde6f0;
    padding: 5px 5px 5px 5px;
    color: #0176B8;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 10px;
}

.dashboard__content .subcategorydetail_part:hover {
    color: white;
    background: #0176B8;
    border: 1px solid #0176B8;
    border-width: 2px;
}

.dashboard__content .listcategory_title {
    font-weight: 200;
    cursor: pointer;
    text-decoration: none;
    font-size: 17px;
    padding-left: 17px;
    width: 80%;
}

.dashboard__content .lists_category {
    border-bottom: 1px solid #dde6f0;
    padding: 5px;
    list-style: none;
    color: #0176B8;
}

.dashboard__content .title_part {
    border: 1px solid #dde6f0;
    font-weight: 500;
}

.dashboard__content .title_part2 {
    border: 1px solid #dde6f0;
    font-weight: 300;
    word-break: break-word;
}

.dashboard__content .maincategory_title {
    padding: 0px;

}

@media screen and (max-width: 319px) {

    .dashboard__content .card__layout,
    .dashboard__content .row {

        overflow: hidden;
        width: 100%;
        margin-top: 10%;
        border-radius: 0px;
        padding: 0px 0px 0px 0px;
        border: 1px;
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media screen and (max-width: 319px) {
    .dashboard__content .maincategory_title {
        padding: 0px;
    }
}

@media screen and (max-width: 319px) {
    .dashboard__content .category_title {
        width: 100%;
        height: 50px;
        padding: 10px;
        border-radius: 8px 8px 0px 0px;
    }
}

@media screen and (max-width: 319px) {
    .dashboard__content .subcategory_title {
        text-align: center;
        margin-top: 20px;
        font-weight: 600;
        font-size: 13px
    }
}

@media screen and (max-width: 319px) {
    .dashboard__content .listcategory_title {
        font-weight: 200;
        text-decoration: none;
        font-size: 12px;
        padding-left: 0px;

    }
}

@media screen and (max-width: 767px) {

    .dashboard__content .card__layout,
    .dashboard__content .row {

        overflow: hidden;
        width: 100%;
        margin-top: 10%;
        border-radius: 0px;
        padding: 0px 0px 0px 0px;
        border: 1px;
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media screen and (max-width: 767px) {
    .dashboard__content .maincategory_title {
        padding: 0px;
    }
}

@media screen and (max-width: 767px) {
    .dashboard__content .category_title {
        width: 100%;
        height: 50px;
        padding: 10px;
        border-radius: 8px 8px 0px 0px;
    }
}

@media screen and (max-width: 767px) {
    .dashboard__content .subcategory_title {
        margin-top: 20px;
        font-weight: 600;
        font-size: 14px;
    }
}

@media screen and (max-width: 767px) {
    .dashboard__content .listcategory_title {
        font-weight: 200;
        text-decoration: none;
        font-size: 13px;
        padding-left: 0px;

    }
}

@media screen and (max-width: 1023px) {

    .dashboard__content .row {


        overflow: hidden;
        width: 100%;
        margin-top: 10%;
        border-radius: 0px;
        padding: 0px 0px 0px 0px;
        border: 1px;
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media screen and (max-width: 1023px) {

    .dashboard__content .card__layout {


        background-color: #fff;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        box-shadow: 4px 10px 8px rgb(2 64 108 / 50%);
        margin-left: 3px;
        overflow: hidden;
        width: 48%;
        margin: 3px;
        border-radius: 0px;
        padding: 0px 0px 0px 0px;
        border: 1px;
    }
}

@media screen and (max-width: 1023px) {
    .dashboard__content .maincategory_title {
        padding: 0px;
    }
}

@media screen and (max-width: 767px) {
    .dashboard__content .category_title {
        width: 100%;
        height: 50px;
        padding: 10px;
        border-radius: 8px 8px 0px 0px;
    }
}

@media screen and (max-width: 1023px) {
    .dashboard__content .subcategory_title {
        text-align: center;
        margin-top: 40px;
        cursor: pointer;
        font-weight: 600;
        font-size: 17px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 1023px) {
    .dashboard__content .listcategory_title {
        font-weight: 200;
        text-decoration: none;
        font-size: 13px;
        padding-left: 0px;

    }
}

@media screen and (max-width: 1023px) {
    .dashboard__content .subcategorydetail_part {
        border: 1px solid #dde6f0;
        padding: 5px 10px 5px 5px;
        color: #0176B8;
        border-radius: 5px;

    }
}

@media screen and (max-width: 1023px) {
    .dashboard__content .listcategory_title {
        font-weight: 200;
        cursor: pointer;
        text-decoration: none;
        font-size: 16px;
        padding-left: 0px;
        width: 90%;
    }

}


.user_padding {
    padding: 40px;
    width: 80%;
}

.userdetail_card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
    box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
    border: none;
    margin-bottom: 30px;
}

.row1 {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0px;
    margin-right: 0px;
}

.user-profile {
    background: linear-gradient(132.14deg, #045C9C 20.93%, #4B923F 90.47%);
    flex: 0 0 auto;
    width: 33%;
    height: 206px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    color: white
}

.width {
    width: 67%;
}


h6 {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.2
}

.name {
    font-weight: 400;
    white-space: normal;
    word-break: break-all;
    text-align: left;
    padding: 0px 5px 0px 5px;

}

.user-content {
    margin: auto;
    max-width: 900px;
}

.detail {
    padding-bottom: 10px !important;
    border-bottom: 1px solid #e0e0e0;
    margin-left: 20px;
    padding-top: 23px;
    font-weight: 400;
}

.row2 {
    display: flex;
    flex-wrap: wrap;
}

.email {
    margin-bottom: 10px;
    margin-left: 20px;
    font-weight: 400;
}

p {
    font-size: 14px;
}

.email-address {
    color: #1548d1e8 !important;
    margin-left: 20px;
    font-weight: 500;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

}

.main {
    width: 50%;
    margin-right: 0px;
    padding-bottom: 5px;
    margin-bottom: 0px;
}

.ppc__wrapper>h2,
.ppc__wrapper>h3 {
    color: #00406C;
    margin: 25px 0px;
    line-height: normal;
    font-weight: 600;
}

.ppc__wrapper>h2 {
    font-size: 35px;
    margin-top: 0px;
    padding: 50px;
    background: #e6f2f9;
}

.ppc__wrapper>h2>strong {
    display: block;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
}

.ppc__wrapper>h3,
.ppc__wrapper>ul {
    font-size: 22px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
    padding-left: 15px;
    padding-right: 15px;
}

.ppc__wrapper>p {
    font-size: 14px;
    font-weight: 300;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    color: #1D1617;
}

.ppc__wrapper {
    width: 100%;
    margin-inline: auto;
}

.ppc__wrapper>ul {
    font-size: 14px;
    font-weight: 300;
    padding-left: 35px;
    margin-top: 25px;
    margin-bottom: 25px;
    color: #1D1617;
}


.ppc__wrapper>ul>li {
    // font-size: 14px;
    // font-weight: 300;
    padding-left: 10px;
    // margin-top: 25px;
    margin-bottom: 10px;
    // color: #1D1617;
}

.MuiDataGrid-footerContainer {
    .MuiToolbar-gutters {
        .MuiTablePagination-selectLabel {
            &~ {
                div {
                    [class^="MuiTablePagination-select"] {
                        * {
                            color: var(--bg--dark);
                        }
                    }
                }
            }
        }
    }
}

.css-8vni76 .MuiDataGrid-columnHeader:focus,
.css-8vni76 .MuiDataGrid-cell:focus {
    outline: none !important;
}