:root {
    color-scheme: light dark;
    --brand--primary: #00406C;
    --brand--seondary: rgba(246,67,59,1);
    --brand--danger: rgba(255,39,91,1);
    --brand--peace: #D7DAE3;
    --brand--gray: #818589;
    --brand--accent: #9DB2CE;
    --brand--success: #5AAA77;
    --brand--font--white: rgba(255,255,255,1);
    --brand--font--link: #0176B8;
    --bg--primary: #032D49;
    --bg--dark: #1D1617;
}