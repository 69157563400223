@use '../abstracts' as *;
.btn--pill{
    border: 0;
    background-color: transparent;
    display: inline-block;
    align-items: center;
    padding: .65em 2em;
    font-size: .875rem;
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: relative;
    overflow: hidden;
    &::before {
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.6);
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
    }
    &:focus {
        &::before {
            transition: all 0.5s ease-out;
            opacity: 0;
            width: 160px;
            height: 160px;
            margin-top: -80px;
            margin-left: -80px;
        }
    }
    &.googleBtn {
        // background-color: #4285f4;
        color: var(--brand--font--white);
        padding: 0px;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
        @include flexBox(inline-flex,row,wrap);
        align-items: center;
        // height: 60px;
        // padding-left: .315em;
        padding: 1em 1.35em;
        border-width: 1.5px;
        border-color: #4e4e6c;
        background-color: #4e4e6c;
        border-style: solid;
        // border-radius: .125rem;
        border-radius: 50px;
        justify-content: center;
        @include respond(tablet-lg) {
            justify-content: flex-start;
            padding: .875em 1.35em;
        }
        &:hover {
            background-color: #3f3f71;
            border-color: #3f3f71;
        }
        > .gsuit--icon {
            display: inline-block;
            // background-color: var(--brand--font--white);
            // padding: .975rem;
            line-height: 1;
            font-size: 0;
            > img {
                max-width: 100%;
                width: 2.25rem;
            }
        }
        > .gsuit--text {
            font-size: 1rem;
            font-weight: 500;
            margin-left: .85rem;
            margin-right: 1rem;
        }
    }
}
.MuiButtonBase-root-MuiButton-root{
    color: var(--brand--font--white);
    background-color: var(--brand--primary);
}
.saveBtn {
    padding: 1.15em 3em;
    font-size: 1rem;
    font-weight: 600;
    color: var(--brand--font--white);
    background-color: var(--brand--primary);
    border-radius: 10px;
    box-shadow: 2px 2px 12px rgba(0, 64, 108, 0.19);
    border: 0;
    outline: none;
    position: relative;
    overflow: hidden;
    transition: .3s ease-in all;
    line-height: normal;
    min-width: 250px;
    cursor: pointer;
    &:hover {
        background-color: #0a5587;
    }
    &::before {
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.6);
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
    }
    &:focus {
        background-color: #0a5587;
        &::before {
            transition: all 0.5s ease-out;
            opacity: 0;
            width: 160px;
            height: 160px;
            margin-top: -80px;
            margin-left: -80px;
        }
    }
    &.shrinkbtn {
        padding: 12px 30px;
        min-width: auto;
    }
}
.normal--btn {
    display: inline-block;
    font-weight: 600;
    font-size: .875rem;
    padding: .5em 1.25em;
    border-radius: 1.5rem;
    cursor: pointer;
    background: #02406d;
    border: 2px solid #02406d;
}
.active-class, .suspended-class {
    display: inline-block;
    font-weight: 600;
    font-size: .875rem;
    padding: .75em 1.25em;
    border-radius: 1.5rem;
}
.deleted-class {
    background-color: rgba(255, 39, 91, 0.12);
    color: var(--brand--danger);
}

.cancel-class {
    background-color: rgba(211, 207, 209, 0.055);
    // color: var(--brand--success);
}

.active-class {
    background-color: rgba(42, 255, 178, 0.2);
    color: var(--brand--success);
}

// .active--class {
//     background-color: rgba(42, 255, 178, 0.2);
//     color: var(--brand--success);
// }
.active--class {
  

    font-size: 0.875rem !important;
    padding: 0.5em 1.25em !important;
}


.suspended-class{
    color: rgba(248, 135, 47, 1);
    background-color: rgba(248, 135, 47, 0.2);
}
body {
    .video--button {
        display: inline-flex;
        padding: 0.65rem 3rem;
        line-height: normal;
        align-items: center;
        background-color: var(--brand--danger);
        box-shadow: 0px 14px 15px rgba(246, 65, 60, 0.3);
        border-radius: 50px;
        svg {
            width: 40px;
            height: 40px;
        }
        &:hover {
            background-color: #ef3831;
        }
        &:not([disabled]){
            &:hover {
                background-color: #ef3831;
            }
        }
    }
}
.button__box {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2,1fr);
    @include respond(tablet-lg) {
        display: block;
    }
}
.sidebar-link  {
    color: var(--bg--dark);
    font-weight: 400;
    font-size: 1rem;
    padding: .9em 1.5em;
    display: grid;
    grid-template-columns: 40px 1fr;
    border-radius: 10px;
    align-items: center;
    text-decoration: none;
    span {
        font-weight: 400;
        text-transform: capitalize;
    }
    svg.MuiSvgIcon-root {
        fill: #C2C1E6;
    }
    &.active {
        background: linear-gradient(132.14deg, #045c9c 20.93%, #4b923f 90.47%);
        box-shadow: 0px 14px 15px rgba(2, 64, 109, 0.15);
        color: var(--brand--font--white);
        span {
            font-weight: 600;
            color: var(--brand--font--white);
        }
        svg {
            path {
                fill: var(--brand--font--white);
            }
        }
        svg.MuiSvgIcon-root {
            fill: #fff;
        }
    }
    & + .sidebar-link {
        margin-top: 1rem;
    }
}
body {
    .cancel--btn {
        background-color: rgba(30, 28, 75, 1);
        border-radius: 50px;
        display: inline-flex;
        align-items: center;
        font-weight: 400;
        line-height: normal;
        color: var(--brand--font--link);
        padding: 0.5em 3em .8em;
        font-size: 1rem;
        box-shadow: none;
    }
}
.fw--400 {
    font-weight: 400;
}
.inset0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.profilepic__circle__wrapper {
    max-width: max-content;
    .overlay--btn {
        right: -10px;
        margin-top: 20px;
        pointer-events: all;
        z-index: 1;
        position: relative;
    }
}
.max--480 {
    max-width: 350px;
}
.sku--no {
    background: #272566;
    font-weight: 400;
    font-size: 14px;
    padding: 8px 24px;
    border-radius: 8px;
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
}