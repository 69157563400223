@use '../abstracts' as *;
.avatar--logo {
    margin-top: auto;
    margin-bottom: 2.5rem;
    text-align: center;
}
.menuItem--list {
    margin-bottom: 1rem;
    margin-top: 1rem;
    @include respond(tablet-lg) {
        margin-top: 2.5rem;
    }
}
body {
    .avator--img {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        margin: 0 auto;
        margin-bottom: 20px;
        background: #d4dee8;
    }
}
body {
    aside {
        .sidebarFixed {
            .MuiPaper-root {
                border-right: 1px solid #f2f2f2;
            @media(min-width: 900px) {
                width: 340px;
                }
            }
        }
    }
}
.aside__menubar {
    --size: 21.25em;
    width: var(--size);
    height: 100vh;
    background-color: var(--bg--primary);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    padding: 3em 1.5em;
}
.asidebar--logo {
    display: inline-block;
    svg, img {
        max-width: 100%;
    }
}
