@use '../abstracts' as *;

.input--label {
    color: var(--bg--primary);
    font-weight: 400;
    font-size: .875rem;
    margin-bottom: 0;
    line-height: normal;
    display: block;
    opacity: .7;
}

.dialog__content__box {
    fieldset {
        border: 0px;
    }

    .Mui-error {
        font-size: .8rem;
        font-weight: 500;
        margin-top: 0.25rem;
    }
}

.input--transparent,
.dialog__content__box input,
div.ReactTags__tagInput input.ReactTags__tagInputField {
    background-color: var(--brand--font--white);
    padding: 1.15em 1.25em;
    border-radius: .875em;
    font-size: 1rem;
    font-weight: 500;
    color: var(--bg--dark);
    border: 1px solid var(--brand--peace);
    transition: .13s ease-in all;
    resize: none;
    width: 100%;
    max-width: 100%;

    &:hover {
        border-color: var(--bg--primary)
    }

    &:focus {
        outline: none;
        box-shadow: none;
        border-color: var(--bg--primary);
    }

    &::placeholder {
        color: var(--brand--peace);
        font-weight: 400;
    }

    &[disabled] {
        background-color: #EEF4F8;
        cursor: not-allowed;
    }
}

.input-inner-transparent {
    .MuiOutlinedInput-root {
        background-color: var(--bg--primary);
        border-radius: 0.875em !important;
        font-size: 1rem;
        font-weight: 500;
        color: var(--brand--font--white);
        transition: 0.13s ease-in all;
        resize: none;
        width: 100%;

        fieldset {
            border: 1px solid transparent !important;
        }

        &:hover {
            fieldset {
                border-color: var(--brand--primary) !important;
            }
        }

        input {
            padding: .65rem .75rem !important;

            &::placeholder {
                color: var(--brand--font--link);
                font-weight: 400;
                opacity: 1;
            }
        }
    }
}

.error--pill {
    font-size: .8rem;
    font-weight: 500;
    display: block;
    margin-top: 0.25rem;

    &.text--error {
        color: var(--brand--danger);
    }

    &.text--success {
        color: var(--brand--success);
    }
}

.profilepic__circle__wrapper {
    position: relative;
    --size: 80px;

    .profilepic__circle {
        display: inline-block;
        width: var(--size);
        height: var(--size);
        overflow: hidden;
        border-radius: 50%;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    .pic--uploadBtn {
        position: absolute;
        left: calc(var(--size) - 30px);
        bottom: 0;
        width: calc(var(--size) - 50px);
        height: calc(var(--size) - 50px);
        padding: .25rem;
        background-color: #353273;
        border-radius: 50%;
        border: 2px solid #fff;

        span {

            svg,
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}

.hidden-file-input {
    opacity: 0;
    cursor: pointer;
    height: 90px;
    width: 90px;
    position: absolute;
    left: 0px;
}

.profilepic__circle {
    cursor: pointer;
}

.d-none {
    display: none;
}

.mx--auto {
    margin-left: auto;
    margin-right: auto;
}

.box__form {
    max-width: 760px;
    padding-left: $gutter-size;
    padding-right: $gutter-size;
}

.MuiFormControl-root {
    .MuiFormGroup-root {
        flex-direction: row;

        .col-6 {
            +.col-6 {
                .MuiFormControlLabel-root {
                    padding-left: 1rem;
                }
            }
        }
    }
}

.input-inner-dropdown {
    .MuiSelect-select {
        background-color: var(--bg--primary);
        border-radius: 0.875em !important;
        font-size: 1rem;
        font-weight: 500;
        color: var(--brand--font--white);
        transition: 0.13s ease-in all;
        resize: none;
        width: 100%;
        padding-top: 1.25rem !important;
        padding-bottom: 1.25rem !important;
        padding-left: 1.25rem;

        &~fieldset {
            border: 1px solid transparent;
            border-radius: 0.875em;
        }
    }

    .MuiOutlinedInput-root {
        &:hover {
            .MuiSelect-select {
                &~fieldset {
                    border-color: var(--brand--primary);
                }
            }
        }
    }
}

.radio--select {
    .MuiTypography-root {
        font-weight: 400;
    }

    .Mui-checked {
        .MuiSvgIcon-root {
            color: var(--brand--primary);
        }
    }
}

.img--uploadBg {
    background: var(--bg--primary);
    padding: 10px;
    border-radius: 15px;
    // height: 205px;
    // max-width: 235px;
    position: relative;
    margin-bottom: 20px;
}

.remove--pic {
    position: absolute;
    right: -6px;
    top: -6px;
    background: rgb(255 39 91 / 43%);
    border-radius: 50%;
    padding: 3px;
    z-index: 1;
}

.w--100 {
    .MuiDialog-paper {
        width: 100%;
        background: #f0f0f4;
        padding: 20px;
    }
}

.MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.75) !important;
}

.support--text {
    color: var(--bg--primary);
    font-size: .875rem;
    margin-bottom: 0rem;
    line-height: normal;
    opacity: .5;
    font-weight: 400;
}

.super-app-theme--header {
    background: #f9fafb;

    .MuiDataGrid-iconButtonContainer {
        visibility: visible !important;
        width: auto !important;

        svg {

            // opacity: 1 !important;
            path {
                fill: var(--brand--font--link);
            }
        }
    }

    .MuiDataGrid-menuIcon {
        display: none !important;
    }
}

.datagrid__layout {
    .datagrid--custom {
        border: 0;
        background: #fff;
        padding: 25px 25px 0 25px;
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.03);

        .MuiDataGrid-columnHeaders {
            border: 0;
            outline: none !important;
        }

        .MuiDataGrid-cell {
            border-bottom-color: #f2f3f6;
            outline: none !important;
        }

        .MuiDataGrid-row.Mui-selected,
        .MuiDataGrid-row:hover,
        .MuiDataGrid-row.Mui-selected:hover {
            background: rgba(227, 227, 232, 0.16);
        }
    }

    .MuiTablePagination-actions {
        button {
            svg {
                path {
                    fill: rgb(212, 212, 212);
                }
            }

            &:not(.Mui-disabled) {
                svg {
                    path {
                        fill: #05406d;
                    }
                }
            }
        }
    }

    .MuiDataGrid-footerContainer {
        border-top: 0;
    }

    .MuiTextField-root {
        margin: 0;
        margin-bottom: 1.5rem;

        .MuiInputBase-root {
            border: 1px solid rgb(212, 209, 209);
            color: var(--bg--dark);
        }

        button {
            color: var(--brand--peace);
        }

        input {
            padding: 15px 12px;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border-width: 0px !important;
        border-color: transparent !important;
    }
}