div.ReactTags__tags {
    position: relative;
}
.ReactTags__selected {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 100%;
}
.ReactTags__editTagInput {
    margin-right: 10px;
    margin-top: 10px;
}
.ReactTags__editTagInput .ReactTags__editTagInputField {
    background: #232157;
    font-size: 0.85rem;
    font-weight: 400;
    padding: 6px 16px !important;
    box-sizing: border-box;
    display: inline-block;
    border-radius: 50px;
    line-height: 1.6 !important;
    border: 1px solid transparent;
    height: auto;
    width: auto;
}
.ReactTags__editTagInput .ReactTags__editTagInputField:focus {
    border-color: var(--brand--primary);
}


/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid transparent;
    color: rgba(255,255,255,0.87);
    background-color: rgba(255, 255, 255, 0.16);
    display: inline-flex;
    padding: 8px 16px 8px;
    margin: 0px;
    margin-right: 10px;
    cursor: pointer !important;
    border-radius: 50px;
    font-weight: 400;
    font-size: 0.85rem;
    align-items: center;
    line-height: 1;
    margin-top: 10px;
}
div.ReactTags__selected a.ReactTags__remove {
    color: #9c9c9c;
    margin-left: 5px;
    cursor: pointer;
}
div.ReactTags__selected span.ReactTags__tag .ReactTags__remove {
    display: inline-flex;
    width: 18px;
    height: 18px;
    background: rgb(127 127 155);
    line-height: 0;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;
    margin-left: 10px;
    padding-right: 0px;
    padding-top: 2px;
    -webkit-tap-highlight-color: transparent;
    color: rgb(77 76 117);
    font-size: 21px;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
    color: #000 !important;
    font-weight: normal !important;
    font-size: 14px !important;
}
div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 200px;
}
div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 15px 10px;
    margin: 0;
}
div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}