@use '../abstracts' as *;
.newstepperui__block {
    padding: 1.25rem 0rem 2.5rem;
    background: #13123e;
    border-radius: 6px;
    width: 100%;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-bottom: 20px;
    .MuiStepLabel-horizontal {
        .MuiStepLabel-alternativeLabel {
            .MuiStepIcon-root {
                color: #fb8735;
            }
        }
        &.Mui-disabled {
            .MuiStepLabel-alternativeLabel {
                .MuiStepIcon-root {
                    color: #232157;
                    .Mui-completed {
                        color: green;
                    }
                }
            }
        }
    }
    .MuiStepLabel-horizontal {
        .MuiStepLabel-alternativeLabel {
            .MuiStepIcon-root.Mui-completed {
                color: #1DAF9C;
                border: 1.5px solid #fff;
                border-radius: 50%;
                background: #fff;
            }
        }
    }
    .MuiStepConnector-horizontal {
        > span {
            border-color: #323067;
            border-style: dashed;
        }
    }
    .Mui-completed + .Mui-completed {
        .MuiStepConnector-horizontal {
            > span {
                border-color: #159176;
            }
        }
    }
}
.mt--ng--md-40 {
    margin-top: 10px;
    @include respond(tablet-md) {
        margin-top: -45px;
    }
}
.max--contentbox {
    position: relative;
    transform: translateX(-50%);
    left: 50%;
}
.text--danger {
    font-Size: 1rem;
    color: #f43f3f;
    vertical-align: top;
    bottom: 0;
    line-height: 1;
    margin-left: 4px;
}
.bdr-rad--6 {
    fieldset {
        border-radius: 8px;
    }
}
.dashboard__content.newstepperui {
    padding-bottom: 0;
    padding-top: 1rem;
}
.col--arrow {
    margin-left: auto;
    margin-bottom: 20px;
    @include respond(desktop-lg) {
        position: absolute;
        margin: 0;
        top: 260px;
        right: 15px;
    }
}
.md-col-auto {
    @include respond(tablet-md) {
        flex: 0 0 auto;
        min-width: auto;
    }
}
.align-items-md-end {
    align-items: flex-end;
}
.md-col-auto {
    @include respond(desktop-xl) {
        flex: 0 0 auto;
        min-width: auto;
    }
}
.pagination--none {
    .MuiDataGrid-footerContainer {
        display: none;
    }
}
.pagination--end {
    padding-bottom: 20px;
    .MuiPagination-ul {
        justify-content: flex-end;
    }
}
.top__cardbox {
    background: radial-gradient(100.85% 285.11% at 14.52% 92.09%, #232157 0%, #201d75 100%);
    padding: 0rem 1rem 1rem 1.5rem;
    border-radius: 8px;
    box-shadow: 0px 0px 14px 2px rgb(23, 23, 31, 0.3);
    display: inline-block;
    .card__columns--inner--icon {
        background: rgba(255,255,255,0.05);
        width: 105px;
        height: 105px;
    }
}
.top__cardbox__price {
    background: linear-gradient(230.26deg, #ff275bde 12.29%, #dc2b5685 72.7%);
    mix-blend-mode: luminosity;
    padding: 1rem 1rem 1rem;
    span {
        display: inline-block;
        padding-left: 20px;
        margin-top: .75rem;
    }
}

.lmt-txt{
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.top__cardbox--content {
    > label {
        font-size: 14px;
        font-weight: 500;
        display: block;
        margin-bottom: 7px;
        position: relative;
        padding-left: 20px;
        &::before {
            content: '';
            position: absolute;
            left: 0px;
            top: 5px;
            width: 10px;
            height: 10px;
            background-color: #fda833;
            display: inline-block;
            border-radius: 50%;
        }
    }
    > p {
        font-weight: 600;
        font-size: 22px;
        margin: 0;
        line-height: 1.25;
        padding-left: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
.tab__boxes {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    label {
        background: #383663;
        border-radius: 50px;
        padding: 10px 20px;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
        font-weight: 400;
        cursor: pointer;
    }
    input[type="checkbox"] {
        display: none;
        &:checked + label {
            background: linear-gradient(74.28deg, #F75238 32.74%, #FFBA33 132.21%);
            font-weight: 600;
        }
    }
}

.pointer-none {
    pointer-events: none;
    // background-color: #282665 !important;
    // opacity: .35;


    &.bgBtn--none {
        button {

            background-color: transparent;
            svg {
                box-shadow: 0px 0px 10px 2px rgb(255,39,91,0.34);
                border-radius: 50px;
                height: 28px;
                width: 28px;
            }
        }
     }
     .delete--row {
        display: none;
        background-color: transparent;
     }
}

.btnHide {
    display: none !important;
}

.allPointer {
    .pointer-none {
        pointer-events:initial;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgb(0, 0, 0, 0.14), 0px 1px 5px 0px rgb(0, 0, 0, 0.12);
        background-color: #282665 !important;
        opacity: 1;

        &.bgBtn--none {
            background-color: #1e1c4b00 !important;
            box-shadow: none;
            button {
                background-color: #282665;
            }
            .delete--row {
                background-color: transparent;
             }
         }
         .delete--row {
            display: inline-block;
         }
    }
}
.ball__received {
    position: relative;
    padding-left: 30px;
    img {
        position: absolute;
        width: 22px;
        left: 0;
        top: 0;
    }
}
.pulse--anim {
    animation: pulse 2s infinite;
    box-shadow: 0px 0px 5px rgb(253, 161, 52, 0.71);
    margin-left: 1rem;
}
@-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(253, 161, 52, 0.2);
    }
    70% {
        -webkit-box-shadow: 0 0 0 8px rgba(253, 161, 52, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(253, 161, 52, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(253, 161, 52, 0.2);
      box-shadow: 0 0 0 0 rgba(253, 161, 52, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 8px rgba(253, 161, 52, 0);
        box-shadow: 0 0 0 8px rgba(253, 161, 52, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(253, 161, 52, 0);
        box-shadow: 0 0 0 0 rgba(253, 161, 52, 0);
    }
}
div {
    &[aria-describedby="alert-dialog-order"] {
        background: #232157;
        padding: 20px;
        overflow: initial;
        > h2 {
            font-weight: 600;
            font-size: 1.25rem;
            padding: 10px;
        }
        > .MuiDialogContent-root {
            padding-left: 10px;
            padding-bottom: 0;
            margin-top: 0px;
            > p {
                .row {
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 15px;
                    margin-top: 15px;
                    > div {
                        font-size: 15px;
                        font-weight: 500;
                        opacity: 1;
                        color: #ffffff;
                        border: 1px solid #2e2d5e;
                        background: #7370b8;
                        padding: 7px 21px;
                        border-radius: 8px;
                        cursor: pointer;
                    }
                    button {
                        // background: transparent;
                        // color: #5c93ff;
                        // text-decoration: underline;
                        // margin-top: 8px;
                        // font-weight: 400;
                        margin-top: 25px;
                    }
                }
            }
        }
        & > {
            .MuiBox-root {
                bottom: 0 !important;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
.app__cs__modal {
    .CenterAlign {
        top: 25%;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -25%);
      }

      .Overlay {
        background-color: rgba(0, 0, 0, 0.55);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        display: none;
        z-index: 9999;
        @include respond(tablet-md) {
            width: calc(100% - 340px);
            left: auto;
            right: 0;
        }
      }

      .Overlay.Show {
        display: block;
      }

      .Modal {
        position: fixed;
        bottom: -150vh;
        background-color: #191840;
        width: 100%;
        box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
        left: 0;
        padding: 0 12px 12px;
        transition: all 0.5s ease-out;
        z-index: 10000;
        max-height: 100vh;
        @include respond(tablet-md) {
            width: calc(100% - 340px);
            left: auto;
            right: 0;
        }
      }

      .Modal.Show {
        bottom: 0;
      }

      .Close {
        top: 0px;
        right: 2px;
      }

      .Alert {
        position: fixed;
        padding: 0.75rem 1.25rem;
        border: 1px solid transparent;
        top: 0;
        left: 0;
        color: #fff;
        background-image: linear-gradient(
          90deg,
          #ff9a8b 0%,
          #ff6a88 55%,
          #ff99ac 100%
        );
        margin: 0;
        width: 100%;
        text-align: center;
        z-index: 10;
      }

      .Alert:empty {
        padding: 0;
      }

      .dashboard__topbar {
        display: none;
      }
}
.workerprofile__wrapper {
    --size: 8rem;
    background: #232157;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 4px 4px 0px 5px rgba(30,27, 83,0.62);
    &--container {
        display: grid;
        grid-row-gap: 1rem;
        @include respond(tablet-md) {
            grid-template-columns: var(--size) 1fr;
            grid-row-gap: 0rem;
            grid-column-gap: 2rem;
        }
    }
    &--img {
        width: var(--size);
        height: var(--size);
        overflow: hidden;
        border-radius: 0.25rem;
        border-radius: 50%;
        box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.05), 0px 0px 0px 13px rgb(255, 255, 255, 0.04);
        margin-top: .565rem;
        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.worker-profile-heading {
    font-size: 1.35rem;
    color: #fff;
    margin-bottom: 0rem;
    line-height: 1.5;
    font-weight: 600;
    position: relative;
    padding-right: 2rem;
    width: max-content;
    margin-bottom: 1rem;
    .verified__status {
        position: absolute;
        right: 0;
        top: 0;
    }
}
.td__mailinfo {
    position: relative;
    padding-left: 2rem;
    margin-top: 0.75rem;
    color: #e5e5e5;
    font-weight: 400;
    font-size: .9rem;
    width: max-content;
    padding-right: 2rem;
    > span {
        position: absolute;
        left: 0;
        top: -1px;
        width: 20px;
        > svg {
            max-width: 100%;
        }
    }
    .verified__status {
        position: absolute;
        right: 0;
        top: 0;
    }
}
.workerprofile__bio {
    font-size: .875rem;
    font-weight: 400;
    margin-bottom: 0;
    color: #e5e5e5;
    white-space: normal;
    word-break: break-all;
    line-height: 1.4;
}
.profile__quality {
    position: relative;
    margin-bottom: 1rem;
    &--status {
        position: absolute;
        left: 28px;
        bottom: -17px;
    }
    .MuiChip-colorSuccess {
        background: #66bb6a !important;
        color: #fff !important;
        font-weight: 600 !important;
        min-width: 75px !important;
    }
}
.specificlist__wrapper {
    @include respond(tablet-md) {
        // position: absolute;
        // right: 0;
        // width: 100%;
        max-width: 380px;
        // top: -20px;
        // z-index: 1;
    }
}
.shrinkBtn  {
    .input--transparent, .dialog__content__box input {
        padding: 0.5em 1.25em;
    }
    .btn--pill {
        padding: 1em 2.15em;
    }
}
.row__gridset {
    .card__columns {
        grid-template-columns: repeat(3,1fr);
    }
}
.input__py__shrink.input-inner-transparent {
    .MuiOutlinedInput-root {
        padding-top: 0.4em;
        padding-bottom: 0.4em;
    }
}
.dynamic__spacing {
    @include respond(desktop-lg) {
        margin-top: 50px !important;
        padding-top: 50px !important;
    }
}
.pt--1 {
    padding-top: 1rem;
}
.pt--0 {
    padding-top: 0rem;
}
